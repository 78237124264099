var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item !== null && _vm.item.status !== undefined)?_c('div',[(
      _vm.item.analyzed != '' 
      && _vm.$store.state.user.role.startsWith('customer')
    )?_c('v-chip',{staticClass:"chip",attrs:{"label":"","color":_vm.$store.state.deviceStatusColors[7],"dark":""}},[_vm._v(" "+_vm._s(_vm.$store.state.deviceStatusNames[7])+" ")]):(
      _vm.$store.state.user.role.startsWith('reception')
      && (_vm.item.status === 6 || _vm.item.status === 7 || _vm.item.status === 11)
    )?_c('v-chip',{staticClass:"chip",attrs:{"label":"","color":_vm.$store.state.deviceStatusColors[7],"dark":""}},[_vm._v(" 保管 ")]):(_vm.item.status === 11)?_c('v-chip',{staticClass:"chip",attrs:{"label":"","color":_vm.$store.state.deviceStatusColors[_vm.item.status],"dark":""},on:{"click":function($event){return _vm.showErrMsg(_vm.item)}}},[_vm._v(" "+_vm._s(_vm.$store.state.deviceStatusNames[_vm.item.status])+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [(hover)?_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-message-text")]):_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-message-text-outline")])]}}],null,true)})],1)]}}],null,false,796856151)},[_c('span',[_vm._v(_vm._s(_vm.getErrMsg(_vm.item)))])])],1):(
      _vm.item.status != 6
    )?_c('v-chip',{staticClass:"chip",attrs:{"label":"","color":_vm.$store.state.deviceStatusColors[_vm.item.status],"dark":_vm.item.status != 10}},[_vm._v(" "+_vm._s(_vm.$store.state.deviceStatusNames[_vm.item.status])+" ")]):(
      _vm.item.status == 6 
      && _vm.item.log_collected !== null && _vm.item.log_collected !== ''
      && _vm.item.device_uploaded !== null && _vm.item.device_uploaded !== '' 
      && _vm.item.log_uploaded !== null && _vm.item.log_uploaded !== ''
    )?_c('v-chip',{staticClass:"chip",attrs:{"label":"","color":_vm.$store.state.deviceStatusColors[6],"dark":""}},[_vm._v(" "+_vm._s(_vm.$store.state.deviceStatusNames[6])),_c('v-progress-circular',{staticClass:"ml-2",attrs:{"indeterminate":"","color":"white","size":"20"}})],1):(
      _vm.item.status == 6 
      && (_vm.item.log_collected === null || _vm.item.log_collected === '')
      && _vm.item.device_uploaded !== null && _vm.item.device_uploaded !== '' 
    )?_c('v-chip',{staticClass:"chip",attrs:{"label":"","color":_vm.$store.state.deviceStatusColors[6],"dark":""}},[_vm._v(" "+_vm._s(_vm.$store.state.deviceStatusNames[6])+" "),_c('v-progress-circular',{staticClass:"ml-2",attrs:{"indeterminate":"","color":"white","size":"20"}})],1):(
      _vm.item.status == 6 
      && (
        _vm.item.log_collected != '' 
        && (_vm.item.device_uploaded == '' || _vm.item.device_uploaded == null || _vm.item.log_uploaded == '' || _vm.item.log_uploaded == null)
      )
    )?_c('v-chip',{staticClass:"chip",attrs:{"label":"","color":_vm.$store.state.deviceStatusColors[6],"dark":""}},[_vm._v(" アップロード待機中 ")]):(
      _vm.item.status == 6
      && (
        (_vm.item.log_collected == '' || _vm.item.log_collected == null)
        && (_vm.item.device_uploaded == '' || _vm.item.device_uploaded == null)
      )
    )?_c('v-chip',{staticClass:"chip",attrs:{"label":"","color":_vm.$store.state.deviceStatusColors[6],"dark":""}},[_vm._v(" アップロード待機中 ")]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }