<template>
  <div v-if="item !== null && item.status !== undefined">

    <!-- 医療機関の場合、解析が完了している場合は、ステータスによらず「解析完了」 -->
    <v-chip
      class="chip"
      v-if="
        item.analyzed != '' 
        && $store.state.user.role.startsWith('customer')
      "
      label
      :color="$store.state.deviceStatusColors[7]"
      dark
    >
      {{$store.state.deviceStatusNames[7]}}
    </v-chip>

    <!-- デバイス受付センターの場合、かつ「解析中」または「解析完了」状態、かつアップロードが済んでいる場合は、「保管」と表示 -->
    <!-- 臨床使用時の暫定的な対応、本格対応では解析状態も示す必要があるため表示内容も含めて整理が必要 -->
    <v-chip
      class="chip"
      v-else-if="
        $store.state.user.role.startsWith('reception')
        && (item.status === 6 || item.status === 7 || item.status === 11)
      "
      label
      :color="$store.state.deviceStatusColors[7]"
      dark
    >
      保管
    </v-chip>

    <!-- 解析エラーの場合はエラー情報を含めて表示 -->
    <v-chip
      class="chip"
      v-else-if="item.status === 11"
      label
      :color="$store.state.deviceStatusColors[item.status]"
      dark
      @click="showErrMsg(item)"
    >
      {{$store.state.deviceStatusNames[item.status]}}
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-hover v-slot="{ hover }" >
              <v-icon size="30" v-if="hover">mdi-message-text</v-icon>
              <v-icon size="20" v-else>mdi-message-text-outline</v-icon>
            </v-hover>
          </v-btn>
        </template>
        <span>{{ getErrMsg(item) }}</span>
      </v-tooltip>    
    </v-chip>

    <!-- 「解析中」以外の場合、定義情報によって表示する -->
    <v-chip
      class="chip"
      v-else-if="
        item.status != 6
      "
      label
      :color="$store.state.deviceStatusColors[item.status]"
      :dark="item.status != 10"
    >
      {{$store.state.deviceStatusNames[item.status]}}
    </v-chip>

    <!-- 解析中、かつ行動記録用有り、かつ行動記録用紙・心電データ共にアップロード済みの場合、処理中アイコンも表示 -->
    <v-chip
      class="chip" 
      v-else-if="
        item.status == 6 
        && item.log_collected !== null && item.log_collected !== ''
        && item.device_uploaded !== null && item.device_uploaded !== '' 
        && item.log_uploaded !== null && item.log_uploaded !== ''
      "  
      label
      :color="$store.state.deviceStatusColors[6]"
      dark
    >
      {{$store.state.deviceStatusNames[6]}}<v-progress-circular indeterminate color="white" size="20" class="ml-2"></v-progress-circular> 
    </v-chip>

    <!-- 解析中、かつ行動記録用無し、かつ心電データアップロード済みの場合、処理中アイコンも表示 -->
    <v-chip 
      class="chip"
      v-else-if="
        item.status == 6 
        && (item.log_collected === null || item.log_collected === '')
        && item.device_uploaded !== null && item.device_uploaded !== '' 
      " 
      label 
      :color="$store.state.deviceStatusColors[6]"
      dark
    >
      {{$store.state.deviceStatusNames[6]}} <v-progress-circular indeterminate color="white" size="20" class="ml-2"></v-progress-circular>  
    </v-chip>

    <!-- 解析中、かつ行動記録用紙有り、かつ、行動記録用紙もしくは心電データどちらかがアップロード未済みの場合、文字列のみ -->
    <v-chip
      class="chip"
      v-else-if="
        item.status == 6 
        && (
          item.log_collected != '' 
          && (item.device_uploaded == '' || item.device_uploaded == null || item.log_uploaded == '' || item.log_uploaded == null)
        )
      "
      label
      :color="$store.state.deviceStatusColors[6]"
      dark
    >
        アップロード待機中
    </v-chip>

    <!-- 解析中、かつ行動記録用紙無し、かつ、心電データがアップロード未済みの場合、文字列のみ -->
    <v-chip
      class="chip"
      v-else-if="
        item.status == 6
        && (
          (item.log_collected == '' || item.log_collected == null)
          && (item.device_uploaded == '' || item.device_uploaded == null)
        )
      "
      label
      :color="$store.state.deviceStatusColors[6]"
      dark
    >
        アップロード待機中
    </v-chip>

  </div>
</template>

<script>
export default {
  props: {
    item: Object
  },
  methods: {
    async showErrMsg(item) {
      await this.$root.$alert('エラー', this.getErrMsg(item));
    },
    getErrMsg(item) {
      let msg = 'エラー情報はありません。';
      let cd = '';
      if (item.analysis_error_info) {
        if (item.analysis_error_info.msg) msg = item.analysis_error_info.msg.trim();
        if (item.analysis_error_info.cd) cd = item.analysis_error_info.cd.trim();
      }
      return `${msg}（${cd}）`;
    },
  },
}
</script>

<style scoped>
  .chip {
    display: inline-block;
    text-align: center;
    width: 100%;
  }
</style>